<template>
  <ListViewItem v-bind="c.opportunities"/>
</template>

<script>
import ListViewItem from "@/components/organisms/ListViewItem/ListViewItem";
import {c} from '@/components/constants'
export default {
  components: {
    ListViewItem
  },
  data() {
    return {
      c
    }
  }
}
</script>

<style scoped>

</style>
